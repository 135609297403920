/* eslint-disable sonarjs/no-duplicate-string */
import {
  useCustomerDomainStore,
  useGoogleUserStore,
  useLabelStore,
  useSharedDriveStore,
} from "@/stores";
import { useFilterHelper } from "@/composables/filter-helper";

export function useFileDriveHelper() {
  const googleUserStore = useGoogleUserStore();
  const workspaceDomainsStore = useCustomerDomainStore();
  const sharedDrivesStore = useSharedDriveStore();
  const labelStore = useLabelStore();

  const filters = [
    {
      name: "File name or id",
      id: "search_name",
      components: [
        {
          id: "file_id_or_search_name", // key for the filter in the query string of the api (required)
          operator: "CONTAINS",
          component: "VTextField",
          minWidth: 400,
          inputData: {
            label: "Search file name or file id...",
            placeholder: "Search file name or file id...",
          },
          value: null,
          nullValue: null,
          primary: true,
        },
      ],
    },
    {
      name: "Location",
      id: "LOCATION",
      section: "Structure",
      components: [
        {
          id: "location",
          component: "VSelect",
          operator: "=",
          ignoreValidation: false,
          ignore: "partial",
          value: null,
          nullValue: null,
          inputData: {
            clearable: true,
            label: "Location",
            placeholder: "Not filtered",
            options: [
              {
                itemText: "Shared drive",
                itemValue: "shared_drive", // links to subComponents -> hidden -> checkHiddenComponent -> value
              },
              {
                itemText: "My Drive",
                itemValue: "my_drive",
              },
              {
                itemText: "Folder",
                itemValue: "folder",
              },
            ],
          },
          apiConverter: (value, operator = null, assignedProperty = "key") => {
            const results = [
              {
                key: "shared_drive",
                id: "actorType",
                operator: "=",
                value: "SHARED_DRIVE",
              },
              {
                key: "my_drive",
                id: "actorType",
                operator: "=",
                value: "MY_DRIVE",
              },
            ];
            return useFilterHelper().getResultFromApiConverter(
              results,
              value,
              assignedProperty,
              operator
            );
          },
          subComponents: [
            {
              id: "driveId", // key used in query string + backend
              component: "StoreSelect",
              operator: "=",
              ignoreValidation: true,
              hidden: (components, option) =>
                checkHiddenComponent(
                  components,
                  {
                    value: "shared_drive", // value of the parent component (selection of location shared drive in this case)
                    id: "location",
                  },
                  option
                ),
              filter: {
                id: "search",
                operator: "CONTAINS",
              },
              options: "function",
              inputData: {
                placeholder: "Any",
                combobox: true,
                custom: true,
                clearable: true,
                store: sharedDrivesStore,
                sortBy: "name",
                sortOrder: "asc",
                itemValue: "sharedDriveId",
                itemText: "name",
                itemSubText: "sharedDriveId",
              },
              // property_type: "string",
            },
            {
              id: "owner.emailAddress",
              component: "StoreSelect",
              operator: "=",
              ignoreValidation: true,
              hidden: (components, option) =>
                checkHiddenComponent(
                  components,
                  {
                    value: "my_drive",
                    id: "location",
                  },
                  option
                ),
              filter: {
                id: "name",
                operator: "CONTAINS",
              },
              inputData: {
                placeholder: "Any",
                combobox: true,
                custom: true,
                clearable: true,
                store: googleUserStore,
                itemValue: "primaryEmail",
                itemText: "name.fullName",
                itemSubText: "primaryEmail",
              },
            },
            {
              id: "parent",
              ignoreValidation: false,
              component: "VTextField",
              operator: "=",
              hidden: (components, option) =>
                checkHiddenComponent(
                  components,
                  {
                    value: "folder",
                    id: "location",
                  },
                  option
                ),
              filter: {
                id: "name",
                operator: "CONTAINS",
              },
              inputData: {
                placeholder: "Enter folder ID",
                combobox: true,
                custom: true,
                clearable: true,
              },
            },
          ],
        },
      ],
    },

    {
      name: "Labels",
      id: "LABELS",
      section: "Structure",
      any_label: {
        getQueryString: function (_operatorValue, _selectedValues) {
          return {
            ignoreValidation: false,
            operator: ">",
            id: "file_stats.total_labels",
            value: "0",
          };
        },
      },
      unlabeled: {
        getQueryString: function (_operatorValue, _selectedValues) {
          return {
            ignoreValidation: false,
            operator: "=",
            id: "file_stats.total_labels",
            value: "0",
          };
        },
      },
      components: [
        {
          id: "labels_filter",
          component: "VSelect",
          operator: "=",
          ignoreValidation: false,
          ignore: "partial",
          value: null,
          nullValue: null,
          inputData: {
            clearable: true,
            label: "Labels",
            placeholder: "Not filtered",
            options: [
              {
                itemText: "Any label selected",
                itemValue: "labels_selection", // links to subComponents -> hidden -> checkHiddenComponent -> value
              },
              {
                itemText: "Any label",
                itemValue: "any_label",
              },
              {
                itemText: "Unlabeled",
                itemValue: "unlabeled",
              },
            ],
          },
          apiConverter: (value, operator = null, assignedProperty = "key") => {
            const results = [
              {
                key: "any_label",
                id: "file_stats.total_labels",
                operator: ">",
                value: "0",
              },
              {
                key: "unlabeled",
                id: "file_stats.total_labels",
                operator: "=",
                value: "0",
              },
            ];
            return useFilterHelper().getResultFromApiConverter(
              results,
              value,
              assignedProperty,
              operator
            );
          },
          subComponents: [
            {
              id: "labels.label_id",
              component: "StoreSelect",
              operator: "IN",
              hidden: (components, option) =>
                checkHiddenComponent(
                  components,
                  {
                    value: "labels_selection",
                    id: "labels_filter",
                  },
                  option
                ),
              filter: {
                id: "name",
                operator: "CONTAINS",
              },
              options: "function",
              inputData: {
                label: "Label",
                combobox: true,
                custom: true,
                clearable: true,
                store: labelStore,
                placeholder: "Not filtered",
                sortBy: "properties.title",
                sortOrder: "asc",
                itemValue: "id",
                itemText: "properties.title",
              },
            },
          ],
        },
      ],
    },
    {
      name: "File type",
      id: "MIMETYPE",
      section: "Structure",
      components: [
        {
          id: "file_type",
          component: "VSelect",
          operator: "=",
          value: [],
          nullValue: [],
          inputData: {
            label: "File type",
            placeholder: "Not filtered",
            multiple: false,
            clearable: true,
            options: [
              {
                itemText: "Documents",
                itemValue: "document",
              },
              {
                itemText: "Spreadsheets",
                itemValue: "spreadsheet",
              },
              {
                itemText: "Presentations",
                itemValue: "presentation",
              },
              {
                itemText: "Forms",
                itemValue: "form",
              },
              {
                itemText: "Photos & images",
                itemValue: "image",
              },
              {
                itemText: "PDFs",
                itemValue: "pdf",
              },
              {
                itemText: "Videos",
                itemValue: "video",
              },
              {
                itemText: "Shortcuts",
                itemValue: "shortcut",
              },
              {
                itemText: "Folders",
                itemValue: "folder",
              },
              {
                itemText: "Sites",
                itemValue: "site",
              },
              {
                itemText: "Audio",
                itemValue: "audio",
              },
              {
                itemText: "Drawings",
                itemValue: "drawing",
              },
              {
                itemText: "Archives (zip)",
                itemValue: "archive",
              },
            ],
          },
        },
      ],
    },
    {
      name: "Shared with",
      id: "SHARED_WITH",
      section: "Access",
      components: [
        {
          id: "user_properties.permission.type",
          ignoreValidation: false,
          component: "VSelect",
          operator: "=",
          value: [],
          nullValue: [],
          inputData: {
            label: "Shared with",
            placeholder: "Not filtered",
            multiple: false,
            clearable: true,
            options: [
              {
                itemText: "Anyone with the link",
                itemValue: "anyone",
                requiresOperator: false,
              },
              {
                itemText: "Outside trusted network",
                itemValue: "outside_trusted_network",
                requiresOperator: false,
              },
              {
                itemText: "Personal accounts",
                itemValue: "personal_accounts",
                requiresOperator: false,
              },
              {
                itemText: "User or group",
                itemValue: "user_or_group",
              },
              {
                itemText: "Domain with the link",
                itemValue: "domain",
              },
              {
                itemText: "Users or groups in the domain",
                itemValue: "email_domain",
              },
            ],
          },
          apiConverter: (value, operator = null, assignedProperty = "key") => {
            const results = [
              {
                key: "anyone",
                id: "user_properties.permission.type",
                operator: "=",
                value: "anyone",
              },
              {
                key: "outside_trusted_network",
                id: "user_properties.facts.isInsideTrustedNetwork",
                operator: "=",
                value: false,
              },
              {
                key: "personal_accounts",
                id: "user_properties.facts.isPersonalAccount",
                operator: "=",
                value: true,
              },
              {
                key: "user_or_group",
                id: "user_properties.permission.type",
                operator: "=",
                value: "user_or_group",
              },
              {
                key: "domain",
                id: "user_properties.permission.type",
                operator: "=",
                value: "domain",
              },
              {
                key: "email_domain",
                id: "user_properties.permission.type",
                operator: "=",
                value: "email_domain",
              },
            ];
            return useFilterHelper().getResultFromApiConverter(
              results,
              value,
              assignedProperty,
              operator
            );
          },
          subComponents: [
            {
              id: "user_properties.permission.domain",
              component: "StoreSelect",
              operator: "=",
              minWidth: 400,
              hint: "e.g. example.com",
              hidden: (components, option) =>
                checkHiddenComponent(
                  components,
                  {
                    value: "domain",
                    id: "user_properties.permission.type",
                  },
                  option
                ),
              inputData: {
                label: "Shared with domain",
                placeholder: "Any",
                combobox: true,
                custom: true,
                clearable: true,
                store: workspaceDomainsStore,
                itemValue: "domainName",
                itemText: "domainName",
              },
            },
            {
              id: "user_properties.permission.email_address",
              component: "StoreSelect",
              operator: "=",
              hint: "e.g. john@example.com",
              parentKey: "user_or_group",
              minWidth: 400,
              hidden: (components, option) =>
                checkHiddenComponent(
                  components,
                  {
                    value: "user_or_group",
                    id: "user_properties.permission.type",
                  },
                  option
                ),
              inputData: {
                label: "Shared with user or group",
                placeholder: "Any",
                combobox: true,
                custom: true,
                clearable: true,
                store: googleUserStore,
                itemValue: "primaryEmail",
                itemText: "primaryEmail",
              },
            },
            {
              id: "user_properties.permission.allow_file_discovery",
              component: "VSelect",
              hidden: (components, option) =>
                checkHiddenComponent(
                  components,
                  {
                    value: "domain",
                    id: "user_properties.permission.type",
                  },
                  option
                ),
              operator: "=",
              ignoreValidation: true,
              hint: "Optional",
              inputData: {
                label: "Searchability",
                placeholder: "Any",
                clearable: true,
                options: [
                  {
                    itemText: "Can find in search results",
                    itemValue: "Can find in search results",
                  },
                  {
                    itemText: "Must have link to access",
                    itemValue: "Must have link to access",
                  },
                ],
              },
              apiConverter: (
                value,
                operator = null,
                assignedProperty = "key"
              ) => {
                const results = [
                  {
                    key: "Can find in search results",
                    id: "user_properties.permission.allow_file_discovery",
                    operator: "=",
                    value: "true",
                  },
                  {
                    key: "Must have link to access",
                    id: "user_properties.permission.allow_file_discovery",
                    operator: "=",
                    value: "false",
                  },
                ];
                return useFilterHelper().getResultFromApiConverter(
                  results,
                  value,
                  assignedProperty,
                  operator
                );
              },
            },
            {
              id: "user_properties.permission.email_domain",
              component: "VTextField",
              operator: "=",
              hint: "e.g. example.com",
              minWidth: 400,
              hidden: (components, option) =>
                checkHiddenComponent(
                  components,
                  {
                    value: "email_domain",
                    id: "user_properties.permission.type",
                  },
                  option
                ),
              inputData: {
                placeholder: "e.g. example.com",
                clearable: true,
              },
            },
          ],
        },
      ],
    },
    {
      name: "Shared with access permissions",
      section: "Access",
      id: "SHARE_WITH_ACCESS_PERMISSIONS",
      components: [
        {
          id: "user_properties.permission.role",
          component: "VSelect",
          operator: "MATCHES_ANY",
          hideOperator: true,
          inputData: {
            clearable: true,
            multiple: true,
            label: "Shared with access permissions",
            placeholder: "Not filtered",
            options: [
              {
                itemText: "Viewer",
                itemValue: "reader",
              },
              {
                itemText: "Commenter",
                itemValue: "commenter",
              },
              {
                itemText: "Editor",
                itemValue: "writer",
              },
            ],
          },
          value: [],
          nullValue: [],
        },
      ],
    },
    {
      name: "Created",
      id: "CREATED_TIME",
      section: "Activity",
      components: [
        {
          id: "created_time",
          operator: "=",
          component: "VCheckbox",
          inputData: {
            label: "Created",
            placeholder: "Any",
          },
          xs: false,
          value: false,
          nullValue: false,
          inlineSubComponents: true,
          subComponents: [
            {
              id: "created_time.selection",
              component: "VSelect",
              operator: "=",
              small: true,
              hidden: (components, option) =>
                checkHiddenComponent(
                  components,
                  {
                    value: true,
                    id: "created_time",
                  },
                  option
                ),
              inputData: {
                placeholder: "More than",
                options: [
                  {
                    itemText: "More than",
                    itemValue: "more_than",
                  },
                  {
                    itemText: "Less than",
                    itemValue: "less_than",
                  },
                ],
              },
              width: 120,
              value: "more than",
              nullValue: "more than",
            },
            {
              id: "created_time.time",
              component: "VTextField",
              operator: "=",
              small: true,
              hidden: (components, option) =>
                checkHiddenComponent(
                  components,
                  {
                    value: true,
                    id: "created_time",
                  },
                  option
                ),
              inputData: {
                placeholder: "-",
              },
              width: 40,
              minWidth: 40,
              rules: ["numeric"],
              forceRules: true,
            },
            {
              id: "created_time.date",
              component: "VSelect",
              operator: "=",
              small: true,
              minWidth: 100,
              value: "days",
              nullValue: "days",
              appendText: "ago",
              hidden: (components, option) =>
                checkHiddenComponent(
                  components,
                  {
                    value: true,
                    id: "created_time",
                  },
                  option
                ),
              inputData: {
                placeholder: "-",
                appendTextOuter: "ago",
                options: [
                  {
                    itemText: "days",
                    itemValue: "days",
                  },
                  {
                    itemText: "months",
                    itemValue: "months",
                  },
                  {
                    itemText: "years",
                    itemValue: "years",
                  },
                ],
              },
            },
          ],
          fn: (value) => {
            let [time, date] = value.split("-").slice(-2);
            let selection =
              value.split("-").length === 3 ? value.split("-")[0] : undefined;

            return {
              id: "created_time",
              operator: selection === "more_than" ? ">" : "<",
              value: `${time}-${date}`,
            };
          },
        },
      ],
    },

    {
      name: "Last opened",
      id: "LAST_VIEWED",
      section: "Activity",
      components: [
        {
          id: "last_viewed",
          operator: "=",
          component: "VCheckbox",
          inputData: {
            label: "Last opened",
            placeholder: "Any",
          },
          xs: false,
          value: false,
          nullValue: false,
          inlineSubComponents: true,
          subComponents: [
            {
              id: "last_viewed.selection",
              component: "VSelect",
              operator: "=",
              small: true,
              hidden: (components, option) =>
                checkHiddenComponent(
                  components,
                  {
                    value: true,
                    id: "last_viewed",
                  },
                  option
                ),
              inputData: {
                placeholder: "More than",
                options: [
                  {
                    itemText: "More than",
                    itemValue: "more_than",
                  },
                  {
                    itemText: "Less than",
                    itemValue: "less_than",
                  },
                ],
              },
              width: 120,
              value: "more than",
              nullValue: "more than",
            },
            {
              id: "last_viewed.time",
              component: "VTextField",
              operator: "=",
              small: true,
              hidden: (components, option) =>
                checkHiddenComponent(
                  components,
                  {
                    value: true,
                    id: "last_viewed",
                  },
                  option
                ),
              inputData: {
                placeholder: "-",
              },
              width: 40,
              minWidth: 40,
              rules: ["numeric"],
              forceRules: true,
            },
            {
              id: "last_viewed.date",
              component: "VSelect",
              operator: "=",
              small: true,
              minWidth: 100,
              value: "days",
              nullValue: "days",
              appendText: "ago",
              hidden: (components, option) =>
                checkHiddenComponent(
                  components,
                  {
                    value: true,
                    id: "last_viewed",
                  },
                  option
                ),
              inputData: {
                placeholder: "-",
                appendTextOuter: "ago",
                options: [
                  {
                    itemText: "days",
                    itemValue: "days",
                  },
                  {
                    itemText: "months",
                    itemValue: "months",
                  },
                  {
                    itemText: "years",
                    itemValue: "years",
                  },
                ],
              },
            },
          ],
          fn: (value) => {
            let [time, date] = value.split("-").slice(-2);
            let selection =
              value.split("-").length === 3 ? value.split("-")[0] : undefined;

            return {
              id: "last_viewed",
              operator: selection === "more_than" ? ">" : "<",
              value: `${time}-${date}`,
            };
          },
        },
      ],
    },
    {
      name: "Last modified",
      id: "LAST_MODIFIED",
      section: "Activity",
      components: [
        {
          id: "modified_time",
          operator: "=",
          component: "VCheckbox",
          inputData: {
            label: "Last modified",
            placeholder: "Any",
          },
          xs: false,
          value: false,
          nullValue: false,
          inlineSubComponents: true,
          subComponents: [
            {
              id: "modified_time.selection",
              component: "VSelect",
              operator: "=",
              small: true,
              hidden: (components, option) =>
                checkHiddenComponent(
                  components,
                  {
                    value: true,
                    id: "modified_time",
                  },
                  option
                ),
              inputData: {
                placeholder: "More than",
                options: [
                  {
                    itemText: "More than",
                    itemValue: "more_than",
                  },
                  {
                    itemText: "Less than",
                    itemValue: "less_than",
                  },
                ],
              },
              width: 120,
              value: "more than",
              nullValue: "more than",
            },
            {
              id: "modified_time.time",
              component: "VTextField",
              operator: "=",
              small: true,
              hidden: (components, option) =>
                checkHiddenComponent(
                  components,
                  {
                    value: true,
                    id: "modified_time",
                  },
                  option
                ),
              inputData: {
                placeholder: "-",
              },
              width: 40,
              minWidth: 40,
              rules: ["numeric"],
              forceRules: true,
            },
            {
              id: "modified_time.date",
              component: "VSelect",
              operator: "=",
              small: true,
              minWidth: 100,
              value: "days",
              nullValue: "days",
              appendText: "ago",
              hidden: (components, option) =>
                checkHiddenComponent(
                  components,
                  {
                    value: true,
                    id: "modified_time",
                  },
                  option
                ),
              inputData: {
                placeholder: "-",
                appendTextOuter: "ago",
                options: [
                  {
                    itemText: "days",
                    itemValue: "days",
                  },
                  {
                    itemText: "months",
                    itemValue: "months",
                  },
                  {
                    itemText: "years",
                    itemValue: "years",
                  },
                ],
              },
            },
          ],
          fn: (value) => {
            let [time, date] = value.split("-").slice(-2);
            let selection =
              value.split("-").length === 3 ? value.split("-")[0] : undefined;

            return {
              id: "modified_time",
              operator: selection === "more_than" ? ">" : "<",
              value: `${time}-${date}`,
            };
          },
        },
      ],
    },

    {
      name: "Special status",
      section: "Special status",
      id: "AVAILABILITY",
      components: [
        {
          id: "trashed",
          operator: "=",
          component: "VCheckbox",
          inputData: {
            label: "Trashed",
          },
          xs: false,
          value: false,
          nullValue: false,
        },
        {
          id: "orphaned",
          operator: "=",
          component: "VCheckbox",
          inputData: {
            label: "Orphaned",
          },
          xs: false,
          value: false,
          nullValue: false,
          apiConverter: (value, operator = null, assignedProperty = "key") => {
            const results = [
              {
                key: true,
                id: "is_orphaned",
                operator: "=",
                value: true,
              },
            ];
            return useFilterHelper().getResultFromApiConverter(
              results,
              value,
              assignedProperty,
              operator
            );
          },
        },
      ],
    },
  ];

  const checkHiddenComponent = (components, condition, option) => {
    return !components.find(({ id, value }) => {
      const conditionIsArray = Array.isArray(condition.value);
      if (
        !option ||
        (conditionIsArray && condition.value.includes(option)) ||
        (!conditionIsArray && condition.value === option)
      ) {
        const IFConditionAsArray = conditionIsArray
          ? condition.value.includes(value)
          : false;

        const IFValueAsArray = Array.isArray(value)
          ? value.includes(condition.value)
          : false;

        const IFBothString = value === condition.value;

        return (
          id === condition.id &&
          (IFConditionAsArray || IFValueAsArray || IFBothString)
        );
      }
    });
  };

  const calculateSharedDrivePermissions = (sharedDrive) => {
    if (sharedDrive.userProperties.length === 0) return [];
    return sharedDrive.userProperties.map(({ permission }) => ({
      displayName: permission.displayName,
      role: permission.role,
      id: permission.id,
      emailAddress: permission.emailAddress,
      photoLink: permission.photoLink,
    }));
  };

  const calculateSharedWith = (stats) => {
    const {
      totalShares,
      sharedWithAnyone,
      sharedWithDomains,
      sharedWithUsers,
      sharedWithGroups,
    } = stats;

    const sharedState = [
      totalShares === 0 && { styling: sharedWithLabels[0], count: "" },
      sharedWithAnyone > 0 && {
        styling: sharedWithLabels[1],
        count: sharedWithAnyone,
      },
      sharedWithDomains > 0 && {
        styling: sharedWithLabels[2],
        count: sharedWithDomains,
      },
      (sharedWithUsers > 0 || sharedWithGroups > 0) && {
        styling: sharedWithLabels[3],
        count: sharedWithUsers + sharedWithGroups,
      },
    ];

    return sharedState.filter((item) => item);
  };

  const calculateFileDiscovery = (stats) => {
    const { sharedWithAnyoneFileDiscovery, sharedWithDomainsFileDiscovery } =
      stats;

    const sharedState = [
      sharedWithAnyoneFileDiscovery > 0 && {
        label: fileDiscoveryLabels[0],
        count: sharedWithAnyoneFileDiscovery,
      },
      sharedWithDomainsFileDiscovery > 0 && {
        label: fileDiscoveryLabels[1],
        count: sharedWithDomainsFileDiscovery,
      },
    ];

    return sharedState.filter((item) => item);
  };

  const sharedWithLabels = [
    {
      label: "Not shared",
    },
    {
      label: "Anyone with the link",
    },
    {
      label: "Anyone within the domain",
    },
    {
      label: "User or group",
    },
  ];

  const fileDiscoveryLabels = ["Anyone", "Domains"];

  const allFilters = [...filters];

  return {
    filters,
    allFilters,
    calculateSharedWith,
    calculateFileDiscovery,
    calculateSharedDrivePermissions,
  };
}
