export default {
  LAYOUTS: {
    DEFAULT_LAYOUT: "layout-default",
    AUTH_LAYOUT: "layout-auth",
  },
  MODULES: {
    SECURITY_AND_AUDITING: {
      name: "File security for Google Drive",
      value: "SECURITY_AND_AUDITING",
      icon: "ShieldCheck",
    },
  },
  FILTER_ARTICLE_MAPPING: {
    last_viewed: "10191214",
  },
  SETTING_GROUPS: import.meta.env.DEV
    ? {
        SETTINGS: {
          name: "Settings",
          value: "SETTINGS",
          icon: "Cog8Tooth",
        },
        DEVELOPMENT: {
          name: "Development",
          value: "DEVELOPMENT",
          icon: "code-bracket",
        },
      }
    : {
        SETTINGS: {
          name: "Settings",
          value: "SETTINGS",
          icon: "Cog8Tooth",
        },
      },

  USER_PROFILE_MENU: {
    USER_PROFILE: {
      name: "User Profile",
      value: "USER_PROFILE",
      icon: "User-circle",
    },
    LOGOUT: {
      name: "Logout",
      value: "LOGOUT",
      icon: "Logout",
    },
  },
};
