import { defineStore } from "pinia";
import { log } from "@/components/helpers/logger-helper";
import { ref, computed, inject } from "vue";
import {
  ref as firebaseRef,
  child,
  get,
  getDatabase,
  onValue,
} from "firebase/database";
import { CustomerService } from "@/apis/app-management";
import { useAuthStore, useErrorStore, useNotificationStore } from "@/stores";
import { useIntercom } from "@/composables/intercom";
import { useOpenReplay } from "@/composables/openreplay";

const STORE_ID = "customer-store";

export const useCustomerStore = defineStore(STORE_ID, () => {
  log.file();
  const dbRef = firebaseRef(getDatabase());
  const authStore = useAuthStore();
  const errorStore = useErrorStore();
  const notificationStore = useNotificationStore();
  const activeCustomer = ref(null);
  const customerTrials = ref(null);
  const usageData = ref(null);
  const activeCustomerId = computed(() => {
    return (
      activeCustomer?.value?.customerId || localStorage.getItem("customerId")
    );
  });
  const multipleCustomers = computed(() => {
    return authStore?.user?.customers?.length > 1;
  });
  const latestVersion = ref(null);
  const newAppVersionAvailable = ref(false);

  const create = errorStore.withErrorHandling(
    async (customer) => {
      try {
        const response = await CustomerService.createNewCustomer({
          name: customer.name,
          marketing: customer.marketing,
          agreedToTerms: customer.agreedToTerms,
          partnerId: customer.partnerId,
        });
        log(response);
        localStorage.removeItem("customerId");
      } catch (error) {
        return error.status;
      }
    },
    STORE_ID,
    "create",
    "Failed to create customer",
    [409]
  );

  const updateCustomer = errorStore.withErrorHandling(
    async (id, customer, message = {}) => {
      const res = await CustomerService.updateCustomer(id, customer);
      const customerIndex = authStore.user.customers.findIndex(
        (customer) => customer.id === id
      );
      authStore.user.customers[customerIndex] = res;
      if (res.id && message) {
        const successMessage =
          message.success || `Customer ${res.name} updated successfully`;
        notificationStore.showToast(successMessage, "success");
      }
      return res;
    },
    STORE_ID,
    "update",
    "Failed to update customer"
  );

  const updateCustomerAdmin = errorStore.withErrorHandling(
    async (id, customer, message = {}) => {
      const res = await CustomerService.updateCustomerAdmin(id, customer);

      if (res.id) {
        const successMessage =
          message.success || `Request to update admin email send`;
        notificationStore.showToast(successMessage, "success");
      }
      return res;
    },
    STORE_ID,
    "request admin update",
    "Failed to request admin update for customer"
  );

  const verifyAdminEmail = errorStore.withErrorHandling(
    async (id, customer, verificationCode, message = {}) => {
      try {
        const body = {
          etag: customer.etag,
          otpPassword: verificationCode,
        };
        const res = await CustomerService.approveAdmin(id, body);

        if (res.id) {
          const successMessage =
            message.success || `Admin email verified successfully`;
          notificationStore.showToast(successMessage, "success");
        }
        return res;
      } catch (error) {
        if (error.status === 403 || error.status === 400) {
          const errorMessage = message.error || `Authorization code is invalid`;
          notificationStore.showToast(errorMessage, "error");
        }
      }
    },
    STORE_ID,
    "verify admin email",
    "Failed to verify admin email for customer",
    [403, 400]
  );

  const getUsage = async () => {
    if (activeCustomerId.value) {
      console.log("activeCustomerId.value", activeCustomerId.value);
      get(child(dbRef, `customers/${activeCustomerId.value}/usage`))
        .then((snapshot) => {
          console.log("val", snapshot.val());
          if (snapshot.exists()) {
            console.log(snapshot.val());
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.log("activeCustomerId.value is not defined");
    }
  };

  const getFromUserById = async (id) => {
    const customer = authStore.user.customers.find(
      (customer) => customer.customerId === id
    );
    if (customer) {
      return customer;
    }
    log.warning("Customer not found");
    return null;
  };

  const list = computed(() => {
    return authStore?.user?.customers;
  });

  const getCustomer = errorStore.withErrorHandling(
    async (customerId) => {
      if (!customerId) {
        customerId = activeCustomerId.value;
      }
      return await CustomerService.getCustomer(customerId);
    },
    STORE_ID,
    "get",
    "Failed to get customer"
  );

  // todo: simplify / split complexity (max 15)
  const setActiveCustomer = async (customerId, updatedCustomerTrials) => {
    if (customerId) {
      let currentActiveCustomerId = activeCustomerId.value;
      const customerFromUser = await getFromUserById(customerId);
      const customer = await getCustomer(customerId);
      if (customerFromUser) {
        activeCustomer.value = customerFromUser;
        customerTrials.value = updatedCustomerTrials;
        localStorage.setItem("customerId", customerId);
        if (
          activeCustomer.value.googleCustomerId &&
          activeCustomer.value.googleCustomerId !== "null"
        ) {
          localStorage.setItem(
            "googleCustomerId",
            activeCustomer.value.googleCustomerId
          );
        }
        if (activeCustomer.value.initSyncStatus) {
          localStorage.setItem(
            "initSyncStatus",
            activeCustomer.value.initSyncStatus
          );
        }
        if (!currentActiveCustomerId) {
          activeCustomer.value = customerFromUser;
          currentActiveCustomerId = customerId;
        }

        // Set customer subscription data, stripe_customer_id, stripe_subscription_id, stripe_subscription_status, subscription_products
        // Set customer subscription data: stripe_customer_id, stripe_subscription_id, stripe_subscription_status, subscription_products
        [
          { key: "stripeCustomerId", value: customer.stripeCustomerId },
          { key: "stripeSubscriptionId", value: customer.stripeSubscriptionId },
          {
            key: "stripeSubscriptionStatus",
            value: customer.stripeSubscriptionStatus,
          },
          { key: "subscriptionProducts", value: customer.subscriptionProducts },
          { key: "partnerId", value: customer.partnerId },
        ].forEach(({ key, value }) => {
          if (value) {
            activeCustomer.value[key] = value;
          }
        });

        await useIntercom().setActiveUser({
          ...authStore.user,
          latestVersion: latestVersion.value,
          newAppVersionAvailable: newAppVersionAvailable.value,
          initSyncStatus: activeCustomer.value?.initSyncStatus,
          customerStatus: activeCustomer.value?.status,
          stripeSubscriptionStatus:
            activeCustomer.value?.stripeSubscriptionStatus,
          partnerId: activeCustomer.value?.partnerId,
        });

        useOpenReplay().setUserInfo(
          authStore.user,
          latestVersion.value,
          activeCustomer.value.status
        );

        if (latestVersion.value === null) {
          await checkVersion();
        }

        return customerFromUser;
      }
    }
  };

  const updateCustomerSetting = errorStore.withErrorHandling(
    async (key, value) => {
      return await CustomerService.updateCustomerSetting(
        activeCustomerId.value,
        { key, value }
      );
    },
    STORE_ID,
    "update customer setting",
    "Failed to update customer setting"
  );

  const appVersion = inject("appVersion");

  function compareVersions(v1, v2) {
    const versionNumber = (v) => parseInt(v.replace(/\./g, ""), 10);

    return versionNumber(v1) - versionNumber(v2);
  }

  // Version check
  const checkVersion = () => {
    return new Promise((resolve) => {
      const db = getDatabase();
      const versionRef = firebaseRef(db, "appData/version");

      onValue(
        versionRef,
        (snapshot) => {
          latestVersion.value = snapshot.val();
          newAppVersionAvailable.value =
            compareVersions(latestVersion.value, appVersion) > 0;

          useIntercom().setActiveUser({
            ...authStore.user,
            latestVersion: latestVersion.value,
            newAppVersionAvailable: newAppVersionAvailable.value,
          });

          useOpenReplay().setUserInfo(
            authStore.user,
            latestVersion.value,
            activeCustomer.value.status
          );
          resolve();
        },
        (error) => {
          console.error("Error fetching version:", error);
        }
      );
    });
  };

  return {
    activeCustomer,
    create,
    updateCustomer,
    updateCustomerSetting,
    setActiveCustomer,
    multipleCustomers,
    updateCustomerAdmin,
    verifyAdminEmail,
    list,
    getUsage,
    usageData,
    getCustomer,
    activeCustomerId,
    customerTrials,
  };
});
