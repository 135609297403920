import { defineStore } from "pinia";
import { ref, computed, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { version } from "../../../package.json";
import {
  getAuth,
  signInWithCredential,
  GoogleAuthProvider,
} from "firebase/auth";
import { decodeCredential, googleOneTap } from "vue3-google-login";

import {
  AuthService,
  OpenAPI as AppManagementAPI,
} from "@/apis/app-management";
import { OpenAPI as SecurityAuditingAPI } from "@/apis/security-auditing";
import { log } from "@/components/helpers/logger-helper";
import { useIntercom } from "@/composables/intercom";
import { useOpenReplay } from "@/composables/openreplay";
import {
  useCustomerStore,
  useErrorStore,
  useNotificationStore,
  useOrgUnitsStore,
  useUserPreferenceStore,
} from "@/stores";

// ========================= //
// ======= CONSTANTS ======= //
// ========================= //

const STORE_ID = "auth-store";
const TOKEN_EXPIRATION_KEY = "tokenExpirationTime";
const GOOGLE_USER_KEY = "googleUser";
const LOGIN_CHECK_INTERVAL = 1000 * 60 * 60; // 60 min
const MAX_LOGIN_DURATION_HOURS = 8;
const TOKEN_REFRESH_INTERVAL_MS = 1000 * 60 * 60 * 4; // ms * sec * min * hours (4 hours)

export const useAuthStore = defineStore(STORE_ID, () => {
  log.file();

  // ========================= //
  // ========= STATE ========= //
  // ========================= //
  const userPreferenceStore = useUserPreferenceStore();
  const router = useRouter();
  const auth = getAuth();

  const errorStore = useErrorStore();
  const customerStore = useCustomerStore();
  const orgUnitsStore = useOrgUnitsStore();

  const refreshLoading = ref(false);
  const maxLoggedInExpireTime = ref(null);
  const checkLoginTimestampInterval = ref(null);
  const refreshFirebaseInterval = ref(null);
  const googleUser = ref(null);
  const googleCredential = ref(null);
  const firebaseUser = ref(null);
  const user = ref(null);

  // ===================== //
  // ====== GETTERS ====== //
  // ===================== //

  const restrictionMessage =
    "This section presents limited actions due to the lack of privileges. If you believe this is an error, contact your administrator";

  const hasPrivilege = computed(() => {
    return (
      requiredPrivileges,
      orgUnitId = userPreferenceStore.selectedOrgUnit.orgUnitId
    ) => {
      if (!Array.isArray(requiredPrivileges)) {
        requiredPrivileges = [requiredPrivileges];
      }

      if (!requiredPrivileges?.[0]) return true;

      if (user.value) {
        const activeCustomer = useCustomerStore().activeCustomer;
        if (activeCustomer) {
          let hasPrivilege = false;
          requiredPrivileges.some((privilege) => {
            const matchedPrivilege = orgUnitsStore.getHiddenChildrenFromList(
              user.value.privileges[activeCustomer.customerId]?.[privilege],
              true
            );

            if (matchedPrivilege) {
              hasPrivilege =
                !matchedPrivilege[0] ||
                matchedPrivilege.includes(orgUnitId) ||
                orgUnitId === "ALL";
            }
          });
          return hasPrivilege;
        }
      }
      return false;
    };
  });

  const privileges = computed(() =>
    Object.values(
      user.value.privileges[customerStore.activeCustomer.customerId]
    )
  );

  const hasGlobalPrivilege = computed(() =>
    privileges.value.some((privilege) => !privilege[0])
  );

  const flatPrivilegesOrgUnits = computed(() => [
    ...new Set(privileges.value.flat()),
  ]);

  // setup the auth listener which we will use to check if the auth is initialized
  let initialAuthStateResolved = ref(false);

  // ====================================== //
  // ========= MAIN FUNCTIONALITY ========= //
  // ====================================== //

  const generalLogin = async (credential) => {
    try {
      log.function();

      const decodedCredential = decodeCredential(credential);
      if (decodedCredential.email.includes("@gmail")) {
        errorStore.addError({
          error: decodedCredential.email,
          errorContext: {
            errorType: "GENERAL_LOGIN_ERROR",
            message:
              "You cannot sign in to Florbs with a personal account. Please use your work account instead.",
            source: STORE_ID,
            function: "generalLogin",
          },
        });
        return 403;
      }

      // Set Google user information and timestamp
      setGoogleUser(credential);
      setLoginTimeStamp();

      // Log in to Firebase and Backend
      await loginToFirebase();
      const backendResponse = await loginToBackend();

      // Handle backend response
      if (backendResponse.id) {
        log.success("Successfully logged in to the backend.");

        setSessionInLocalStorage({
          credential: credential,
          expiresAt: decodedCredential.exp,
        });

        return 200;
      }

      // check new user
      if (backendResponse.status === 404 || backendResponse.status === 403) {
        return backendResponse.status;
      }

      return backendResponse;
    } catch (error) {
      log.error("General login failed:", error.message);

      errorStore.addError({
        error,
        errorContext: {
          errorType: "GENERAL_LOGIN_ERROR",
          message: error.message,
          source: STORE_ID,
          function: "generalLogin",
        },
      });

      return 500;
    }
  };

  const loginToFirebase = async () => {
    log.function();

    if (!googleCredential.value) {
      log.warning("No googleCredential found. Firebase login aborted.");
      return false;
    }

    const credential = GoogleAuthProvider.credential(googleCredential.value);
    log.debug("credential", credential);

    try {
      log.debug("Trying to login to Firebase with credential...");
      const userCredential = await signInWithCredential(auth, credential);
      firebaseUser.value = getAuth().currentUser;

      log.debug("userCredential", userCredential);
      log.debug("firebaseUser", firebaseUser.value);

      setAPITokens(userCredential.user.accessToken);

      log.success("Logged in to Firebase successfully.", userCredential.user);

      // monitor the Firebase token expiration time and refresh it when needed
      if (!refreshFirebaseInterval.value) {
        resetRefreshInterval();
      }

      return true;
    } catch (error) {
      log.error("Error logging in to Firebase:", error.message);
      // TODO check the message the user receives
      errorStore.addError({
        error,
        errorContext: {
          errorType: "FIREBASE_LOGIN_ERROR",
          message: error.message,
          source: STORE_ID,
          function: "loginToFirebase",
        },
      });
      logout();
      return false;
    }
  };

  const loginToBackend = async () => {
    log.function();

    try {
      const response = await AuthService.login();
      if (!response.id) {
        log.error(
          "Error logging in to backend:",
          response.message,
          response.status,
          response
        );
        return response;
      }

      setUserInfo(response);
      await setActiveCustomer();

      return response;
    } catch (error) {
      if (
        error.status === 404 ||
        error.status === 403 ||
        error.status === 401
      ) {
        // if not on login page, redirect to login page
        if (
          router.currentRoute.value?.name !== "login" &&
          router.currentRoute.value?.name !== "activate free scan"
        ) {
          router.push({ name: "login" });
        }

        error.message = "Unable to login, please try again.";

        // If we get these status codes, we need to return them to the caller
        return error;
      }
      const errorContext = {
        errorType: "BACKEND_AUTH_ERROR",
        message: error.message,
        source: STORE_ID,
        function: "loginToBackend",
        data: {
          body: error.body,
          status: error.status,
          statusText: error.statusText,
          url: error.url,
        },
      };
      errorStore.addError({ error, errorContext });
      throw error;
    }
  };

  const checkGoogleLogin = async () => {
    log.function();

    log.debug("Checking if we have a valid credential...");

    if (isCredentialValid()) {
      log.debug(
        "We have a valid credential, so we can set the googleUser and refresh the firebasetoken with the credential..."
      );

      setGoogleUser(localStorage.getItem(GOOGLE_USER_KEY));
      setLoginTimeStamp();

      return true;
    }
    log.debug("Invalid or no credential. Trying one-tap login.");
    logout();

    // ignore for now if a user disabled the one tap once this
    // wil not work
    // code will not be used

    // try {
    //   const res = await googleOneTap({
    //     autoLogin: true,
    //     cancelOnTapOutside: false,
    //   });

    //   log.debug("one-tap login response", res);
    //   setGoogleUser(res.credential);
    //   setLoginTimeStamp();

    //   setSessionInLocalStorage({
    //     credential: res.credential,
    //     expiresAt: decodeCredential(res.credential).exp,
    //   });

    //   return res.credential;
    // } catch (error) {
    //   console.log("one-tap login error", error);
    //   router.push({ name: "login" });
    //   return false;
    // }
  };

  const handleNonPublicRouteChange = async () => {
    log.function();
    log.debug("Checking if user is logged in...");

    // Check if we need a new Google login
    const requireNewGoogleLogin =
      !maxLoggedInExpireTime.value || maxLoggedInExpireTime.value < Date.now();

    logLoginStatus(requireNewGoogleLogin);

    if (requireNewGoogleLogin) {
      if (await attemptGoogleLogin()) {
        return true;
      }
    } else {
      log.debug("We don't need to login to Google or Firebase again.");
      return true; // User is still logged in.
    }

    log.debug("returning isLoggedIn", false);
    return false;
  };

  const logout = async (redirect) => {
    log.function();
    log.debug("redirect", redirect);
    log.debug("Logging out...");
    try {
      // Firebase logout
      await auth.signOut();

      // reset cached data
      user.value = null;
      googleUser.value = null;
      googleCredential.value = null;
      firebaseUser.value = null;

      // reset intercom
      try {
        useIntercom().logout();
        useOpenReplay().stop();

        // clear the interval for checking the login timestamp
        clearInterval(checkLoginTimestampInterval.value);
        clearInterval(refreshFirebaseInterval.value);

        // Clear all session info from localStorage
        localStorage.removeItem(GOOGLE_USER_KEY);
        localStorage.removeItem(TOKEN_EXPIRATION_KEY);
      } catch (error) {
        // ignore
      }
    } catch (error) {
      const errorContext = {
        errorType: "LOGOUT_ERROR",
        message: error.message,
        source: STORE_ID,
        function: "logout",
      };
      errorStore.addError({ error, errorContext });
    } finally {
      if (redirect !== false) {
        router.push({ name: "login" });
      }
    }
  };

  // ============================== //
  // ====== HELPER FUNCTIONS ====== //
  // ============================== //

  const setSessionInLocalStorage = (session) => {
    log.function();
    log.debug("session", session);
    localStorage.setItem(GOOGLE_USER_KEY, session.credential);
    localStorage.setItem(TOKEN_EXPIRATION_KEY, session.expiresAt * 1000); // in milliseconds
  };

  const setAPITokens = (token) => {
    log.function();
    AppManagementAPI.TOKEN = token;
    SecurityAuditingAPI.TOKEN = token;
  };

  const setGoogleUser = (credential) => {
    log.function();
    log.debug("credential", credential);
    googleCredential.value = credential;
    const decodedToken = decodeCredential(credential);
    googleUser.value = decodedToken;

    log.debug(
      "GoogleCredential expire time: ",
      new Date(decodedToken.exp * 1000).toString()
    );
  };

  const setLoginTimeStamp = () => {
    log.function();
    maxLoggedInExpireTime.value =
      Date.now() + 1000 * 60 * 60 * MAX_LOGIN_DURATION_HOURS;

    checkLoginTimestampInterval.value = setInterval(
      checkLoginTimestamp,
      LOGIN_CHECK_INTERVAL
    );

    // Triggers when tab is focused again, useful for when tab is inactive for a long time
    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState === "visible") {
        checkLoginTimestamp();
      }
    });
  };

  const setUserInfo = (response) => {
    log.function();

    // merge customerDetails and customers
    const mergedCustomers = response.customerDetails.map((detail) => {
      const matchingCustomer = response.customers.find(
        (c) => c.id === detail.customerId
      );
      return {
        ...detail,
        name: matchingCustomer?.name ?? undefined,
        googleCustomerId: matchingCustomer?.googleCustomerId ?? undefined,
        initSyncStatus: matchingCustomer?.initSyncStatus ?? undefined,
        adminEmail: matchingCustomer?.adminEmail ?? undefined,
      };
    });

    const userInfoFromGoogleCredential = {
      email: googleUser.value.email,
      displayName: googleUser.value.name,
      firstName: googleUser.value.given_name,
      lastName: googleUser.value.family_name,
      headDomain: googleUser.value.hd,
    };

    const userInfoFromBackendApi = {
      id: response.id,
      googleUserId: response.googleUserId,
      photoUrl: response.picture,
      customers: mergedCustomers,
      expirationTime: response.expirationTime,
      authenticationTime: response.authenticationTime,
      picture: response.picture,
      issuedAtTime: response.issuedAtTime,
      privileges: response.privileges,
      intercomHash: response.intercomHash,
    };

    log.debug("userInfoFromGoogleCredential", userInfoFromGoogleCredential);
    log.debug("userInfoFromBackendApi", userInfoFromBackendApi);

    try {
      useOpenReplay().setUserInfo(
        userInfoFromGoogleCredential,
        "unknown",
        mergedCustomers?.status ?? "unknown"
      );
    } catch (e) {
      // ignore
    }

    // Set the user information in the store
    user.value = {
      ...userInfoFromGoogleCredential,
      ...userInfoFromBackendApi,
      appVersion: version,
    };
  };

  const setActiveCustomer = async (activeCustomerId) => {
    log.function();
    log.debug("user", user.value.customers[0].customerId);
    log.debug("activeCustomerId", useCustomerStore().activeCustomerId);

    const currentCustomerId =
      activeCustomerId || useCustomerStore().activeCustomerId || null;

    // check activeCustomerid is available in users customer list
    activeCustomerId = user.value.customers.find(
      (c) => c.customerId === currentCustomerId
    )?.customerId;

    if (!activeCustomerId) {
      activeCustomerId = user.value.customers[0].customerId;
    }

    let customerTrials;
    await AuthService.setActiveCustomer(activeCustomerId).then((response) => {
      customerTrials = response.customerTrials;
    });

    return await useCustomerStore().setActiveCustomer(
      activeCustomerId,
      customerTrials
    );
  };

  const checkLoginTimestamp = () => {
    log.debug("Checking login timestamp...", Date.now().toString());
    const now = Date.now();

    if (now > maxLoggedInExpireTime.value) {
      log.warning(
        "We crossed the 8 hours max logged in time. We need to login again."
      );
      // TODO logout or show one-tap login. For now it logs out. Not sure if this is what we want if the user is actively using the app
      logout();
    }
  };

  const toastTimeouts = ref([]);

  const notifyRefreshFirebaseToken = () => {
    // clear timeouts, otherwise we will get multiple toasts
    toastTimeouts.value.forEach(clearTimeout);
    toastTimeouts.value = [];

    const showToast = (duration, timeout) => {
      useNotificationStore().showToast(
        `Attention: You will be automatically logged out in ${duration} due to inactivity. Any unsaved changes will be lost.`,
        "info",
        timeout
      );
    };

    toastTimeouts.value.push(
      setTimeout(
        () => showToast("5 minutes", 10000),
        TOKEN_REFRESH_INTERVAL_MS - 300000
      )
    );
    toastTimeouts.value.push(
      setTimeout(
        () => showToast("30 seconds", 30000),
        TOKEN_REFRESH_INTERVAL_MS - 30000
      )
    );
  };

  const refreshFirebaseToken = async () => {
    // TODO check if this could be done automatically by Firebase
    log.function();
    try {
      const storedCredential = localStorage.getItem(GOOGLE_USER_KEY);
      if (!storedCredential) {
        throw new Error("No stored Google credentials found.");
      }

      const userCredential = await reauthenticateWithFirebase(storedCredential);
      setAPITokens(userCredential.user.accessToken);

      log.success("Firebase token refreshed successfully.");

      const backendResponse = await loginToBackend();

      if (!backendResponse.id) {
        handleBackendLoginError(backendResponse.message);
        return;
      }
      log.success("Logged in to backend successfully.");

      resetRefreshInterval();

      // return token so we can set it in request.ts when a call failes
      return userCredential.user.accessToken;
    } catch (error) {
      console.error("Error refreshing Firebase token:", error.message);
      const tries = Number(
        localStorage.getItem("refreshFirebaseTokenReloadTries") ?? 0
      );
      // refresh the page which will trigger the login process. Max 5 tries
      if (tries < 5) {
        localStorage.setItem(
          "refreshFirebaseTokenReloadTries",
          (tries + 1).toString()
        );
        window.location.reload();
      }
    }
  };

  const reauthenticateWithFirebase = async (storedCredential) => {
    const credential = GoogleAuthProvider.credential(storedCredential);
    return await signInWithCredential(auth, credential);
  };

  const handleBackendLoginError = (message) => {
    log.error("Error logging in to backend:", message);
    errorStore.addError({
      error: message,
      errorContext: {
        errorType: "BACKEND_AUTH_ERROR",
        message: message,
        source: STORE_ID,
        function: "refreshFirebaseToken",
      },
    });
    logout();
  };

  const isCredentialValid = () => {
    const expirationTime = parseInt(localStorage.getItem(TOKEN_EXPIRATION_KEY));
    const now = Date.now();

    log.debug("Expiration time", new Date(expirationTime).toLocaleString());

    return expirationTime && now < expirationTime;
  };

  const activityTimeout = ref(null);

  const resetInactivityTimer = () => {
    clearTimeout(activityTimeout.value);

    activityTimeout.value = setTimeout(() => {
      // User has been inactive for the set duration. Refreshing Firebase token.
      refreshFirebaseToken();
    }, TOKEN_REFRESH_INTERVAL_MS);
    notifyRefreshFirebaseToken();
  };

  const setupInactivityCheck = () => {
    document.addEventListener("click", resetInactivityTimer);
    resetInactivityTimer();
  };

  const cleanupInactivityCheck = () => {
    document.removeEventListener("click", resetInactivityTimer);
    clearTimeout(activityTimeout.value);
  };

  const resetRefreshInterval = () => {
    notifyRefreshFirebaseToken();
    setupInactivityCheck();
  };

  resetRefreshInterval();

  onUnmounted(() => {
    cleanupInactivityCheck();
  });

  const attemptGoogleLogin = async () => {
    log.debug("Attempting Google login...");
    const hasValidGoogleLogin = await checkGoogleLogin();

    if (hasValidGoogleLogin) {
      log.debug(
        "User is logged in with Google. Proceeding to login to Firebase and the backend..."
      );
      refreshLoading.value = true;

      await loginToFirebase();
      const isLoggedIn = await loginToBackend();

      refreshLoading.value = false;
      return isLoggedIn;
    }

    return false;
  };

  const logLoginStatus = (requireNewGoogleLogin) => {
    log.debug("requireNewGoogleLogin", requireNewGoogleLogin);
    if (
      maxLoggedInExpireTime.value &&
      maxLoggedInExpireTime.value > Date.now()
    ) {
      log.debug(
        "We did not refresh and did not cross the 8 hours max logged in time."
      );
    } else if (!maxLoggedInExpireTime.value) {
      log.debug("We don't have a max logged in time. Probably a refresh.");
    } else {
      log.debug("We crossed the 8 hours max logged in time.");
    }
  };

  return {
    handleNonPublicRouteChange,
    generalLogin,
    logout,
    user,
    hasPrivilege,
    authCurrentUser: firebaseUser,
    refreshLoading,
    refreshFirebaseToken,
    googleCredential,
    loginToFirebase,
    setActiveCustomer,
    initialAuthStateResolved,
    flatPrivilegesOrgUnits,
    hasGlobalPrivilege,
    restrictionMessage,
  };
});
